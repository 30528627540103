import React, { useEffect } from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Dashboard/Actions';
import { setHeaderNameAction } from './../../redux/MainLayout/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Loader from './../../components/Loader';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 8
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const Dashboard = (props) => {
  const { setHeaderNameAction, count, loading, getAllStatisticsDataAction, resetDashboardAction } = props;

  useEffect(() => {
    setHeaderNameAction('Dashboard');
    return () => {
      resetDashboardAction();
    }
  }, [setHeaderNameAction, resetDashboardAction]);

  useEffect(() => {
    if (loading) {
      getAllStatisticsDataAction();
    }
  }, [loading, getAllStatisticsDataAction])

  const classes = useStyles();
  return (
    loading ?
      <Loader />
      :
      <div>
        {count ?
          <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Users
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.users_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Cars
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.cars_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Brands(Makes)
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.makes_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Models
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.models_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Blogs
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.models_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Contact Details
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.contacts_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Booked Test Drive
                  </Typography>
                  <Typography variant="body2" component="p">
                    {count.testdrive_count}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          :
          'No data found'
        }
      </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.Dashboard
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions, setHeaderNameAction }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
