import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    RESET_LOGIN_PARAMS,
} from './Constants';
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const handleLoginRequestAction = (payload) => dispatch => {
    api.post('admin_master/login', { data: payload })
        .then((result) => {
            if (result.success) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    data: {
                        user: result.data.userDetails,
                        token: result.data.token
                    }
                })
            } else {
                dispatch({
                    type: LOGIN_FAILURE,
                    data: {
                        message: result.msg
                    }
                })
            }
        })
        .catch((err) => {
            console.log('err', err);
        });
}

export const handleLogoutRequestAction = () => dispatch => {
    dispatch({
        type: LOGOUT_REQUEST
    })
}

export const resetLoginParamsAction = () => dispatch => {
    dispatch({
        type: RESET_LOGIN_PARAMS
    })
}