import Dashboard from "./Dashboard/Reducer";
import Notification from "./Notification/Reducer";
import Login from "./Login/Reducer";
import Users from "./Users/Reducer";
import MainLayout from "./MainLayout/Reducer";
import CarListing from "./CarListing/Reducer";
import CarEdit from "./CarEdit/Reducer";
import TestDrive from "./TestDrive/Reducer";
import ContactDetails from "./ContactDetails/Reducer";
import Blogs from "./Blogs/Reducer";
import Subscriptions from "./Subscriptions/Reducer";
import MakeModel from "./MakeModel/Reducer";

export default {
  Dashboard,
  Notification,
  Login,
  Users,
  MainLayout,
  CarListing,
  CarEdit,
  TestDrive,
  ContactDetails,
  Blogs,
  Subscriptions,
  MakeModel,
}